import { AuthApi } from 'src/utils/API'
import {
    GET_CONVERSATION_FAIL,
    GET_CONVERSATION_REQUEST,
    GET_CONVERSATION_SUCCESS,
    SET_CHAT_USER,
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAIL,
    MESSAGE_NOTIFICATIONS,
} from 'src/utils/ReduxConstants'

// Current User
export const setCurrentChatUser = (conversation) => async (dispatch) => {
    dispatch({ type: SET_CHAT_USER, payload: conversation })
}

// get Conversation
export const getConversations = () => async (dispatch) => {
    try {
        dispatch({ type: GET_CONVERSATION_REQUEST })

        const { data, result } = await AuthApi.get('/conversations')

        if (result === 'Success' && data.data !== undefined) {
            let conversations = data.data
            dispatch({ type: GET_CONVERSATION_SUCCESS, payload: conversations })

            //filtering new messages
            let unreadConversations = conversations.filter(conversation => conversation.new_messages !== 0)
            dispatch({ type: MESSAGE_NOTIFICATIONS, payload: unreadConversations })
        }
    } catch (error) {
        let message = error?.response?.message || 'Unexpected error occurred!'
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        dispatch({ type: GET_CONVERSATION_FAIL, payload: { status, message, result } })
    }
}

// get messages
export const getMessages = (conversation_id, sender, receiver) => async (dispatch) => {
    let values = { conversation_id, sender, receiver }
    try {
        dispatch({ type: GET_MESSAGES_REQUEST })

        const { data, result } = await AuthApi.post(`/conversation-messages`, values)
        if (result === 'Success' && data.messages !== undefined) {
            let { messages } = data
            let messagesArr = messages.data
            messagesArr.sort(function (a, b) {
                var c = new Date(a.created_at);
                var d = new Date(b.created_at);
                return c - d;
            });
            dispatch({ type: GET_MESSAGES_SUCCESS, payload: messages })
        }
    } catch (error) {
        let message = error?.response?.message || 'Unexpected error occurred!'
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        dispatch({ type: GET_MESSAGES_FAIL, payload: { status, message, result } })
    }
}

//remove read message count from conversations
export const removeNewMessageCount = (conversation_id) => async (dispatch, getState) => {
    const chatData = getState().chatData
    const { conversations } = chatData
    let conversationsArr = conversations;

    //remove new message count
    let index = conversationsArr.findIndex(conversation => conversation.id === conversation_id)
    if (index !== -1) conversationsArr[index].new_messages = 0;

    dispatch({ type: GET_CONVERSATION_SUCCESS, payload: conversationsArr })
}

//add latest conversation to top of list
export const addNewConversation = (notification) => async (dispatch, getState) => {
    const { type, conversation } = notification;

    if (type === 'conversation') {
        const chatData = getState().chatData
        const { conversations } = chatData

        let conversationsArr = conversations;
        conversationsArr = conversationsArr.filter(conversationObj => conversationObj.id !== conversation.id)
        conversationsArr.unshift(conversation)
        dispatch({ type: GET_CONVERSATION_SUCCESS, payload: conversationsArr })
    }

}

