import { CONNECTION_REQ_NOTIFICATIONS, MESSAGE_NOTIFICATIONS } from "src/utils/ReduxConstants"


export const NotificationsReducer = (state = {}, action) => {
    switch (action.type) {

        //Matched List
        case MESSAGE_NOTIFICATIONS:
            return { messageNotifications: action.payload }
        case CONNECTION_REQ_NOTIFICATIONS:
            return { ...state, connectionNotifications: action.payload }

        default:
            return state
    }
}