import React from 'react'
import { MESSAGE_NOTIFICATIONS, USER_CONNECTION_REQUESTS, USER_CONNECTIONS_SUCCESS } from "src/utils/ReduxConstants";
import { Avatar } from '@mui/material';
import { notification as TriggerNotification } from 'antd';

//Manage Notifications
export const manageNotifications = (notification) => async (dispatch, getState) => {

    const { type, conversation, connection_request } = notification;

    //get current data from redux store
    const chatData = getState().chatData
    const { current_chat_user } = chatData

    const { connection_requests = [], user_connections } = getState().userData

    const notificationsData = getState().notificationsData
    const { messageNotifications } = notificationsData

    //copy data to modify
    let notifications = messageNotifications;
    let connectionsArr = connection_requests;
    let userConnectionsArr = user_connections


    switch (true) {

        //if arrived notification is message notification and user is not chatting with the sender of notification
        case (type === 'conversation') && (current_chat_user?.id !== conversation.id):

            let index = notifications.findIndex(notification => notification.id === conversation.id)
            index === -1 ? notifications.push(conversation) : notifications[index] = conversation;

            dispatch({ type: MESSAGE_NOTIFICATIONS, payload: notifications })
            TriggerNotification.open({
                message: conversation.conversation_name,
                description: conversation.recent_message,
                icon: <Avatar src={conversation.profile_pic} alt={conversation.name} />,
                placement: 'bottomRight',
            })
            break;

        //when new connection req. is arrived    
        case (type === 'connection_request') && connection_request.status === 'PENDING':
            connectionsArr.unshift(connection_request)
            userConnectionsArr.unshift(connection_request)
            dispatch({ type: USER_CONNECTION_REQUESTS, payload: connectionsArr });
            dispatch({ type: USER_CONNECTIONS_SUCCESS, payload: userConnectionsArr });

            break;

        //when connection req. is immediately withdrawn by sender
        case (type === 'connection_request') && connection_request.status === 'WITHDRAW':
            connectionsArr = connectionsArr.filter((connection) => connection.id !== connection_request.id);
            userConnectionsArr = userConnectionsArr.filter((connection) => connection.id !== connection_request.id);
            dispatch({ type: USER_CONNECTION_REQUESTS, payload: connectionsArr });
            dispatch({ type: USER_CONNECTIONS_SUCCESS, payload: userConnectionsArr });

            break;

        default:
            break;
    }
}

//remove read notification from Notifications list
export const removeReadNotifications = (conversation_id) => async (dispatch, getState) => {

    const notificationsData = getState().notificationsData
    const { messageNotifications } = notificationsData

    let notifications = messageNotifications;
    //remove from notification
    let filteredNotifications = notifications.filter(notification => notification.id !== conversation_id)
    dispatch({ type: MESSAGE_NOTIFICATIONS, payload: filteredNotifications })
}