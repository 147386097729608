//Loading
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";

//Login
export const USER_AUTH_REQUEST = "USER_LOGIN_REQUEST";
export const USER_AUTH_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_AUTH_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";


//Register
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

//Claiming user
export const SET_CLAIMING_USER = "SET_CLAIMING_USER";

//Matched List
export const MATCHED_LIST_REQUEST = "MATCHED_LIST_REQUEST";
export const MATCHED_LIST_SUCCESS = "MATCHED_LIST_SUCCESS";
export const MATCHED_LIST_FAIL = "MATCHED_LIST_FAIL";

//favorites List
export const FAVORITES_LIST_REQUEST = "FAVORITES_LIST_REQUEST";
export const FAVORITES_LIST_SUCCESS = "FAVORITES_LIST_SUCCESS";
export const FAVORITES_LIST_FAIL = "FAVORITES_LIST_FAIL";

//total user connections List
export const USER_CONNECTIONS_REQUEST = "USER_CONNECTIONS_REQUEST";
export const USER_CONNECTIONS_SUCCESS = "USER_CONNECTIONS_SUCCESS";
export const USER_CONNECTION_REQUESTS = "USER_CONNECTION_REQUESTS";
export const USER_CONNECTIONS_FAIL = "USER_CONNECTIONS_FAIL";

//Current Chat User
export const SET_CHAT_USER = "SET_CHAT_USER";

//Notifications
export const MESSAGE_NOTIFICATIONS = "MESSAGE_NOTIFICATIONS";
export const CONNECTION_REQ_NOTIFICATIONS = "CONNECTION_REQ_NOTIFICATIONS";

//get conversation
export const GET_CONVERSATION_REQUEST = "GET_CONVERSATION_REQUEST";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_CONVERSATION_FAIL = "GET_CONVERSATION_FAIL";

//get conversation messsages
export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";
