import { LOADING_TRUE, LOADING_FALSE } from "../utils/ReduxConstants";

export const LoadingReducer = (state = {}, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return { loading: true, message: action.state };
    case LOADING_FALSE:
      return { loading: false, message: action.payload.message, result: action.payload.result, status: action.payload.status };

    default:
      return state;
  }
};

export const SidebarReducer = (state = {}, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}
