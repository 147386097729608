
import Echo from "laravel-echo"
const { REACT_APP_WEBSOCKETS_URL, REACT_APP_API_URL, REACT_APP_WEBSOCKETS_KEY, REACT_APP_WEBSOCKETS_PORT } = process.env

window.Pusher = require('pusher-js');

//Get userToken
let token;
if (localStorage.getItem("_au_tkn_") !== null) {
    token = localStorage.getItem("_au_tkn_");
}

export const EchoListen = new Echo({
    broadcaster: 'pusher',
    key: REACT_APP_WEBSOCKETS_KEY,
    wsHost: REACT_APP_WEBSOCKETS_URL,
    wsPort: REACT_APP_WEBSOCKETS_PORT,
    wssHost: REACT_APP_WEBSOCKETS_URL,
    wssPort: REACT_APP_WEBSOCKETS_PORT,
    forceTLS: false,
  disableStats: true,
  verifyPeer: false,
    enabledTransports: ['ws', 'wss'],
    //enabledTransports: ['ws'],
    authEndpoint: `${REACT_APP_API_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: 'Bearer ' + token
        },
    },
});
