import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { SidebarReducer, LoadingReducer } from 'src/reducers/AppReducer';
import { AuthReducer, RegisterReducer } from 'src/reducers/GuestReducers';
import { UserDataReducer } from 'src/reducers/UserReducer';
import { USER_LOGOUT } from './ReduxConstants';
import { ChatReducer } from 'src/reducers/ChatReducer';
import { NotificationsReducer } from 'src/reducers/NotiFicationsReducer';

//Collect previous user login data if is there
const userInfoFromStorage = localStorage.getItem("GLOBAL_DATA")
  ? JSON.parse(localStorage.getItem("GLOBAL_DATA")) : { isSignedIn: false, user: '', _au_tkn_: null, isAdmin: false };

userInfoFromStorage._au_tkn_ = localStorage.getItem("_au_tkn_")

//Initial state for the store 
export const initialState = {
  sidebar: { sidebarShow: true, sidebarUnfoldable: false },
  loginStatus: userInfoFromStorage,
  notificationsData: { messageNotifications: [], connectionNotifications: [] }
}

//Combining other reducers
const rootReducer = combineReducers({
  loading: LoadingReducer,
  sidebar: SidebarReducer,
  loginStatus: AuthReducer,
  registerStatus: RegisterReducer,
  userData: UserDataReducer,
  chatData: ChatReducer,
  notificationsData: NotificationsReducer
});

//Main App reducer
const AppReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT: return rootReducer(initialState, action)
    default: return rootReducer(state, action)
  }
};


const middleware = [thunk]

const store = createStore(AppReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
