import { useState } from "react";
import { useDispatch } from "react-redux";
import { LOADING_FALSE } from "src/utils/ReduxConstants";

const useApi = (apiFunc, dataSetterFunc = () => { }, key) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const request = async (...args) => {
        setLoading(true);
        try {
            const { status, data, result } = await apiFunc(...args);
            setData(data);
            //set response Data to setState() if passes from components
            dataSetterFunc(data[key]);
            //api success response
            dispatch({ type: LOADING_FALSE, payload: { status, message: data?.message, result } })
        } catch (err) {
            let message = err?.response?.message || "Unexpected error occurred!"
            let status = err?.response?.status || 500
            let result = err?.response?.result || 'Error'
            setError(err.response.data || { message: 'Internal server error' });
            //api error response
            dispatch({ type: LOADING_FALSE, payload: { status, message, result } })
        } finally {
            setLoading(false);
        }
    };

    return { data, error, loading, request };
};

export default useApi