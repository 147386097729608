import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

const Toast = ({ message, result }) => {


    if (result === 'Success' && message) {
        toast.success(message)
    } else if (result === 'Error' && message) {
        toast.error(message)
    }

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#e1f7f7',
                    }
                }}
            />
        </>
    );
};

export default Toast;
