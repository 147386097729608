import {
    FAVORITES_LIST_FAIL,
    FAVORITES_LIST_REQUEST,
    FAVORITES_LIST_SUCCESS,
    MATCHED_LIST_FAIL,
    MATCHED_LIST_REQUEST,
    MATCHED_LIST_SUCCESS,
    USER_CONNECTIONS_FAIL,
    USER_CONNECTIONS_REQUEST,
    USER_CONNECTIONS_SUCCESS,
    USER_CONNECTION_REQUESTS,
} from 'src/utils/ReduxConstants'

export const UserDataReducer = (state = {}, action) => {
    switch (action.type) {

        //Matched List
        case MATCHED_LIST_REQUEST:
            return { ...state, matchedLoading: true }
        case MATCHED_LIST_SUCCESS:
            return { ...state, matchedLoading: false, matchedUsersList: action.payload }
        case MATCHED_LIST_FAIL:
            return {
                ...state,
                matchedLoading: false,
                message: action.payload.message,
                result: action.payload.result,
                status: action.payload.status,
            }

        //Favorites List
        case FAVORITES_LIST_REQUEST:
            return {
                ...state,
                favoriteLoading: true,
                favoriteUsersListObj: state.favoriteUsersListObj,
                favoriteUsersListArr: state.favoriteUsersListArr,
            }
        case FAVORITES_LIST_SUCCESS:
            return {
                ...state,
                favoriteLoading: false,
                favoriteUsersListObj: action.payload.favoriteUsersListObj,
                favoriteUsersListArr: action.payload.favoriteUsersListArr,
            }
        case FAVORITES_LIST_FAIL:
            return {
                ...state,
                favoriteLoading: false,
                favoriteUsersListObj: state.favoriteUsersListObj,
                favoriteUsersListArr: state.favoriteUsersListArr,
                message: action.payload.message,
                result: action.payload.result,
                status: action.payload.status,
            }

        //Connection request List
        case USER_CONNECTION_REQUESTS:
            return { ...state, connectionLoading: false, connection_requests: action.payload }

        //User Connections List
        case USER_CONNECTIONS_REQUEST:
            return { ...state, connectionLoading: true, user_connections: state.user_connections }
        case USER_CONNECTIONS_SUCCESS:
            return { ...state, connectionLoading: false, user_connections: action.payload }
        case USER_CONNECTIONS_FAIL:
            return {
                ...state,
                favoriteLoading: false,
                user_connections: state.user_connections,
                message: action.payload.message,
                result: action.payload.result,
                status: action.payload.status,
            }

        default:
            return state
    }
}
