import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import 'antd/dist/antd.css'
import './scss/style.scss'
import './app.css'
import { useSelector } from 'react-redux'
import Toast from './reusables/Toast'
import { getFavoritesList, getMatchedList, getUserConnectionsList } from './actions/UserAction'
import { useDispatch } from 'react-redux'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from './ScrollToTop'
import { EchoListen } from './utils/SocketConfig'
import { manageNotifications } from './actions/NotificationsAction'
import { addNewConversation, getConversations } from './actions/ChatActions'
import { Spin } from 'antd'
import useApi from './hooks/UseApi'
import { lastSeenApi } from './request/UserRequest'

const loaderAnimation = (
  <div className='d-flex min-vh-100' style={{ background: 'rgb(229, 246, 253)' }}>
    <Spin className='m-auto' size="large" tip="Loading..." />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const LandingPage = React.lazy(() => import('./views/guest/LandingPage/LandingPage'))
const LoginRegisterPage = React.lazy(() => import('./views/guest/LoginRegisterPage/LoginRegisterPage'))
const RegisterResult = React.lazy(() => import('./reusables/RegisterResult'))
const Posts = React.lazy(() => import('./views/guest/showPosts/Posts'))
const Profile = React.lazy(() => import('./views/guest/profile/Profile'))
const ClaimUser = React.lazy(() => import('src/views/guest/claim/ClaimUser.js'))
const ResetPass = React.lazy(() => import('./views/guest/ResetPass/ResetPass'))
const Help = React.lazy(() => import('./views/guest/help/Help'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const dispatch = useDispatch()
  const loginStatus = useSelector((state) => state.loginStatus)
  let { isSignedIn, _au_tkn_, user_id } = loginStatus
  const { request: lastSeen } = useApi(lastSeenApi)



  //if user is login
  useEffect(() => {
    if (_au_tkn_ !== null && isSignedIn === true && user_id !== null) {
      dispatch(getMatchedList(user_id, _au_tkn_))
      dispatch(getFavoritesList(user_id));
      dispatch(getUserConnectionsList(user_id));
      dispatch(getConversations());

      //Listening to notifications
      EchoListen.private('App.Models.User.' + user_id)
        .notification((notification) => {
          dispatch(manageNotifications(notification))
          dispatch(addNewConversation(notification))
        });

      return () => {
        lastSeen()
        console.log('first')
        EchoListen.leave('App.Models.User.' + user_id)
      };
    }
  }, [])

  //track screensize
  useEffect(() => {
    window.addEventListener('resize', () => setIsMobile(window.innerWidth < 768))
    dispatch({ type: 'set', isMobile })
    return () => window.removeEventListener('resize', () => setIsMobile(window.innerWidth < 768))
  })

  const loadingData = useSelector((state) => state.loading);
  let { loading, message, result } = loadingData;

  return (
    <>
      <Toast result={result} message={message} loading={loading} />
      <HashRouter>
        <React.Suspense fallback={loaderAnimation}>
          <ScrollToTop />
          <Switch>
            <Route path="/home" name="Landing Page" render={(props) => <LandingPage {...props} />} />
            <Route path="/posts/:type" name="Posts" render={(props) => <Posts {...props} />} />
            <Route exact path="/login-register" name="Login Register Page" render={(props) => <LoginRegisterPage {...props} />} />
            <Route exact path="/register-result" name="Registeration Status" render={(props) => <RegisterResult {...props} />} />
            <Route exact path="/profile/:user_id" name="Profile" render={(props) => <Profile {...props} />} />
            <Route exact path="/claim/:user_id" name="Claim User" render={(props) => <ClaimUser {...props} />} />
            <Route exact path="/reset-password/:user_id" name="Reset Password" render={(props) => <ResetPass {...props} />} />
            <Route exact path="/help" name="Reset Password" render={(props) => <Help {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
        </React.Suspense>
      </HashRouter>
    </>
  )
}

export default App
