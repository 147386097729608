import {
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    SET_CLAIMING_USER,
    USER_AUTH_FAIL,
    USER_AUTH_REQUEST,
    USER_AUTH_SUCCESS,
} from "../utils/ReduxConstants";

export const AuthReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_AUTH_REQUEST:
            return { loading: true };
        case USER_AUTH_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_AUTH_FAIL:
            return { loading: false, message: action.payload.message, result: action.payload.result, status: action.payload.status };
        default:
            return state;
    }
};

export const RegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
            return { loading: true };
        case REGISTER_USER_SUCCESS:
            return { loading: false, registerResult: action.payload };
        case REGISTER_USER_FAIL:
            return { loading: false, registerResult: action.payload };
        case SET_CLAIMING_USER:
            return { claimingUserData: { ...action.payload, ...state.claimingUserData } };

        default:
            return state;
    }
}