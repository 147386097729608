import {
    SET_CHAT_USER,
    GET_CONVERSATION_FAIL,
    GET_CONVERSATION_REQUEST,
    GET_CONVERSATION_SUCCESS,
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAIL,
} from '../utils/ReduxConstants'

export const ChatReducer = (state = {}, action) => {
    switch (action.type) {
        //Set current chat user
        case SET_CHAT_USER:
            return { ...state, current_chat_user: action.payload }

        //conversations
        case GET_CONVERSATION_REQUEST:
            return { ...state, loading: true, conversations: state.conversations }

        case GET_CONVERSATION_SUCCESS:
            return { ...state, loading: false, conversations: action.payload }

        case GET_CONVERSATION_FAIL:
            return {
                ...state,
                loading: false,
                conversations: state.conversations,
                message: action.payload.message,
                result: action.payload.result,
                status: action.payload.status,
            }

        //messages
        case GET_MESSAGES_REQUEST:
            return { ...state, loading: true, messages: state.messages }

        case GET_MESSAGES_SUCCESS:
            return { ...state, loading: false, messages: action.payload }

        case GET_MESSAGES_FAIL:
            return {
                ...state,
                loading: false,
                messages: state.messages,
                message: action.payload.message,
                result: action.payload.result,
                status: action.payload.status,
            }

        default:
            return state
    }
}
