import { AuthApi } from "src/utils/API"
import {
    USER_CONNECTION_REQUESTS, FAVORITES_LIST_FAIL, FAVORITES_LIST_REQUEST, FAVORITES_LIST_SUCCESS, LOADING_FALSE,
    MATCHED_LIST_FAIL, MATCHED_LIST_REQUEST, MATCHED_LIST_SUCCESS, USER_CONNECTIONS_FAIL, USER_CONNECTIONS_REQUEST, USER_CONNECTIONS_SUCCESS
} from "src/utils/ReduxConstants"


// Matched List
export const getMatchedList = (user_id) => async (dispatch) => {

    let values = { user_id: user_id }
    try {
        dispatch({ type: MATCHED_LIST_REQUEST })

        const { data, result } = await AuthApi.post('/matched-list', values)

        if (result === 'Success' && data !== undefined) {
            dispatch({ type: MATCHED_LIST_SUCCESS, payload: data.matchedList })
        }

    } catch (error) {
        let message = error?.response?.message || "Unexpected error occurred!"
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        dispatch({ type: MATCHED_LIST_FAIL, payload: { status, message, result } })
    }
}

// Favorites List
export const getFavoritesList = (user_id) => async (dispatch) => {

    let values = { user_id: user_id }
    try {
        dispatch({ type: FAVORITES_LIST_REQUEST })

        const { data, result } = await AuthApi.post('/favorites-list', values)

        if (result === 'Success' && data !== undefined) {
            let favoriteUsersListObj = data.favoritesList
            let favoriteUsersListArr = favoriteUsersListObj.map(user => user.favorited_to);
            dispatch({ type: FAVORITES_LIST_SUCCESS, payload: { favoriteUsersListObj, favoriteUsersListArr } });
        }

    } catch (error) {
        console.log(error);
        let message = error?.response?.message || "Unexpected error occurred!"
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        dispatch({ type: FAVORITES_LIST_FAIL, payload: { status, message, result } })
        dispatch({ type: LOADING_FALSE, payload: { status, message, result } })
    }
}

// total user Connections List
export const getUserConnectionsList = (currentUser) => async (dispatch) => {

    try {
        dispatch({ type: USER_CONNECTIONS_REQUEST })

        const { data, result } = await AuthApi.get('/user-connections')

        if (result === 'Success' && data !== undefined) {
            let user_connections = data.user_connections
            let connection_requests = [];
            user_connections.map((connection) => {
                if (connection.request_receiver === currentUser && connection.status === 'PENDING') {
                    connection_requests.push(connection);
                }
            })
            dispatch({ type: USER_CONNECTIONS_SUCCESS, payload: user_connections });
            dispatch({ type: USER_CONNECTION_REQUESTS, payload: connection_requests });
        }

    } catch (error) {
        let message = error?.response?.message || "Unexpected error occurred!"
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        dispatch({ type: USER_CONNECTIONS_FAIL, payload: { status, message, result } })
        dispatch({ type: LOADING_FALSE, payload: { status, message, result } })
    }
}

