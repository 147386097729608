import { AuthApi } from "src/utils/API"

//favorites----------------------->
export const addToFavoriteAPI = (...args) => AuthApi.post(`/add-to-favorite`, ...args);

export const removeFromFavoriteAPI = (...args) => AuthApi.post(`/remove-from-favorite`, ...args);

//Get user by ID
export const getUserByIdAPI = (value, isSignedIn) => AuthApi.post(`${isSignedIn ? '' : 'guest'}/user`, value)

//Connections--------------->
export const addToConnectionAPI = (...args) => AuthApi.post(`/add-connection`, ...args);

export const respondToConnectionAPI = (...args) => AuthApi.post(`/respond-connection`, ...args);

export const getConnectedConnectionsAPI = () => AuthApi.get(`/connected-users`);

//Availability Status Change API------------>
export const changeAvailabilityAPI = (...args) => AuthApi.post(`/change-availability`, ...args);

//Messaging--------------->
export const createConversationAPI = (...args) => AuthApi.post(`/create-conversation`, ...args);

export const sendMessageAPI = (...args) => AuthApi.post(`/send-message`, ...args);

export const readMessageAPI = (...args) => AuthApi.post(`/mark-read-messages`, ...args);

export const downloadAttachments = async (file_name, conversation_id, file_location) => {

    AuthApi.post(`/download-attachments`, { file_name, conversation_id, file_location }, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers["content-type"],
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
        }).catch((err) => console.log(err));

};


//Posts-------------------->
export const getPostByIdApi = (id) => AuthApi.get(`/post/${id}`);

export const getPostByUserApi = () => AuthApi.get(`/post`);

export const createPostApi = (...args) => AuthApi.post(`/post`, ...args);

export const updatePostApi = (...args) => AuthApi.post(`/update-post`, ...args);

export const deletePostByIdApi = (id) => AuthApi.delete(`/post/${id}`);


//Profile update----------->
export const getProfileApi = () => AuthApi.get(`/get-profile`);
export const updateProfileApi = (values, type) => AuthApi.post(`/update-profile/${type}`, values);
export const lastSeenApi = () => AuthApi.get(`/last-seen`);


//Feedback--------------->
export const getFeedbackByUserApi = () => AuthApi.get(`/user-feedback`);

export const saveFeedbackApi = (...args) => AuthApi.post(`/save-feedback`, ...args);
