import axios from 'axios'
import { startLogout } from 'src/actions/GuestActions';
import store from './store';
const { REACT_APP_API_URL } = process.env

//Get userToken
let token;
if (localStorage.getItem("_au_tkn_") !== null) {
    token = localStorage.getItem("_au_tkn_");
}

//Guest
const GuestApi = axios.create({
    baseURL: REACT_APP_API_URL,
})

//Authorized
const AuthApi = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        Authorization: 'Bearer ' + token
    }
})


//Interceptors
GuestApi.interceptors.response.use(function (response) {
    response.result = 'Success'
    return response;
}, function (error) {

    error.response.result = 'Error'
    if (error?.response?.data) {
        var { message, errors } = error?.response?.data
    } else {
        errors = {}
        message = 'Something went wrong!!'
    }
    error.response.message = message
    error.response.errors = errors

    return Promise.reject(error);
});

AuthApi.interceptors.response.use(function (response) {
    response.result = 'Success'
    return response;
}, function (error) {
    let status = error.response?.status
    error.response.result = 'Error'
    if (error?.response?.data) {
        var { message } = error?.response?.data
    } else {
        message = 'Something went wrong!!'
    }
    error.response.message = message

    // Logout if unauthorized
    if (status === 401) {
        setTimeout(() => store.dispatch(startLogout()), 2000);
    }
    return Promise.reject(error);
});




export { GuestApi, AuthApi }