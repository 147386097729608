import {
    USER_AUTH_FAIL,
    USER_AUTH_REQUEST,
    USER_AUTH_SUCCESS,
    USER_LOGOUT,
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    LOADING_FALSE,
    LOADING_TRUE,
} from '../utils/ReduxConstants'
import { GuestApi } from '../utils/API.js'

// LOGIN
export const startLogin = (values) => async (dispatch) => {
    try {
        dispatch({ type: USER_AUTH_REQUEST })

        const { data, result } = await GuestApi.post('/login', values)

        if (result === 'Success' && data !== undefined) {

            const globalData = {
                isSignedIn: true,
                user_id: data.user.id,
                username: data.user.username,
                is_available: data.user.is_available,
                user: `${data.user.first_name} ${data.user.last_name}`,
                profile_picture: data.profile,
                userType: data.userType,
                isAdmin: false,
            }
            localStorage.setItem('GLOBAL_DATA', JSON.stringify(globalData))
            localStorage.setItem('_au_tkn_', data.token)
            dispatch({ type: USER_AUTH_SUCCESS, payload: globalData })
            let replaceUrl = `${window.location.origin}/#/dashboard`
            window.location.replace(replaceUrl);
            window.location.reload()

        }

    } catch (error) {
        let message = error?.response?.message || "Unexpected error occurred!"
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        dispatch({ type: USER_AUTH_FAIL, payload: { status, message, result } })
    }
}

// reset user
export const resetUser = (is_available) => async (dispatch) => {

    let data = JSON.parse(localStorage.getItem('GLOBAL_DATA'));
    data.is_available = is_available ? 0 : 1

    localStorage.setItem('GLOBAL_DATA', JSON.stringify(data));
    dispatch({ type: USER_AUTH_SUCCESS, payload: data })
    let replaceUrl = `${window.location.origin}/#/login-register`
    window.location.replace(replaceUrl);
    window.location.reload()



}

//register
export const RegisterUser = (values, registerUrl) => async (dispatch) => {
    let registerResult;
    let formData = new FormData
    for (let key in values) {
      if ((key === 'tags' || key === 'relationship_to_community') && values[key].length !== 0) {
            formData.append(key, JSON.stringify(values[key]));
        } else formData.append(key, values[key]);
    }
    try {
        dispatch({ type: LOADING_TRUE })
        dispatch({ type: REGISTER_USER_REQUEST })

        const { data, result, status } = await GuestApi.post(registerUrl, formData)

        if (result === 'Success') {
            dispatch({ type: LOADING_FALSE, payload: { status, message: data.message, result } })
            dispatch({ type: REGISTER_USER_SUCCESS, payload: { result: data.register, message: data.message, errors: data.errors } })
            registerResult = data.register
        }
    } catch (error) {
        registerResult = error?.response?.data?.register
        let message = error?.response?.message || "Unexpected error occurred!"
        let status = error?.response?.status || 500
        let result = error?.response?.result || 'Error'
        let errors = error?.response?.errors || { message: 'Internal server error' }
        dispatch({ type: LOADING_FALSE, payload: { status, message, result } })
        dispatch({ type: REGISTER_USER_FAIL, payload: { status, message, result, errors } })
    } finally {
        if (registerResult !== undefined) {
            let replaceUrl = `${window.location.origin}/#/register-result`
            window.location.replace(replaceUrl);
        }
    }
}

//LOG OUT
export const startLogout = () => async (dispatch) => {
    const globalData = {
        isSignedIn: false,
        _au_tkn_: '',
        username: '',
        user: ``,
        isAdmin: false,
    }
    localStorage.setItem('GLOBAL_DATA', JSON.stringify(globalData))
    localStorage.removeItem('_au_tkn_')
    dispatch({ type: USER_LOGOUT })
    let replaceUrl = `${window.location.origin}/#/login-register`
    window.location.replace(replaceUrl);
    window.location.reload()
}
